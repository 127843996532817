<template>
  <div id="modal" class="modal-moderar">
    <div class="modal-bg">
      <div class="modal-fechar" @click.exact="fecharModal"></div>
      <div class="modal-conteudo">
        <div class="conteudo-mensagem">
          <table>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Quantidade</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(produto, index) in produtos" :key="index">
                <td>{{ produto.produto }}</td>
                <td>{{ produto.quantidade }}</td>
                <td>{{ produto.valor }}</td>
              </tr>
            </tbody>
          </table>
          <div class="detalhes" v-if="produtos.totalPontuacao">
            <p><b>Promoção:</b> {{ produtos.promocao }}</p>
            <p><b>Total de pontos:</b> {{ produtos.totalPontuacao }}</p>
          </div>
          <div class="botoes" v-if="btn">
            <button @click="moderar">Aprovar</button>
            <button class="botao-cancelar" @click.exact="fecharModal">cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      

<script>
import { alterarStatus, moderarCupom } from "@/services/cupom";

export default {
  name: "ModalModerar",
  data() {
    return {
      comentario: "",
    };
  },
  computed: {
    produtos() {
      return this.$store.state.modal.paramsModal.lista
    },
    btn() {
      return this.$store.state.modal.paramsModal.btn
    },
    infoDetalhe() {
      return this.$store.state.modal.paramsModal.infoDetalhe
    }
  },
  methods: {
    enviar() {
      alterarStatus(
        this.$store.state.modal.paramsModal.idCupom,
        this.$store.state.modal.paramsModal.idStatus,
        this.$store.state.user.idUsuario,
        this.comentario
      ).then((resp) => {
        this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: resp.data.sucesso ? "sucesso" : "erro",
        });
      });
    },
    moderar() {
      this.$store.commit("cupom/SET_CARREGADO", false);
      this.infoDetalhe.usuarioPainelId = this.$store.state.user.idUsuario;
      moderarCupom(this.infoDetalhe, this.produtos).then((resp) => {
        if (resp.data.sucesso) {

          this.$store.commit("cupom/SET_CARREGADO", true);
          this.$store.commit("SET_MODAL", {
            ativado: true,
            mensagem: "Cupom moderado com Sucesso!",
            tipo: "sucesso",
          });
          setTimeout(() => {
            document.location.reload(true);
          }, 1500);
        } else {
          this.$store.commit("cupom/SET_CARREGADO", true);
          this.store.commit('SET_MODAL', {
            ativado: true,
            mensagem: "Ocorreu um erro",
            tipo: 'erro',
          });
        }
      });
    },
    fecharModal() {
      this.$store.commit("SET_MODAL", { ativado: false });
    },
  },
};
</script>

<style lang="scss" scoped>
#modal.modal-moderar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 50px 25px 70px 25px;

  @media (max-width: 800px) {
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal-bg {
    position: relative;
    z-index: 1;
    margin: auto;

    @media (max-width: 800px) {
      height: 100%;
    }

    &:before {
      content: "";
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      position: fixed;
    }

    .modal-fechar {
      position: absolute;
      z-index: 1;
      top: 8px;
      right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.1);
      }

      &:before,
      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 4px;
        content: "";
        border-radius: 20px;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .modal-conteudo {
      position: relative;
      width: 100%;

      @media (max-width: 800px) {
        height: 100%;
      }
    }
  }

  .conteudo-mensagem {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    border-radius: 15px;
    max-width: 400px;
    height: auto;
    max-height: 425px;
    padding: 75px 20px 50px 20px;
    text-align: center;

    h2 {
      font-size: 35px;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    p {
      font-size: 22px;
      line-height: 1.1em;
      word-wrap: break-word;
    }

    @media (max-width: 800px) {
      min-height: 0px;
      width: 100vw;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    button {
      margin-top: 20px;
      margin-left: 0;
      padding: 5px 20px;
      border-radius: 15px;
      border: none;
      background: green;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
      height: 26px;
    }

    .botao-cancelar {
      background: $erro-1;
      height: 26px;
    }

    .detalhes {
      margin-top: 20px;

      p {
        text-align: initial;
        font-size: 18px;
        margin: 5px;
        color: #000;
      }

      b {
        font-weight: bold;
      }
    }

    .botoes {
      display: flex;
      justify-content: space-between;
    }
  }

  textarea {
    display: block;
    padding: 5px;
    width: calc(100% - 12px);
    margin: 10px auto;
  }
}
</style>
