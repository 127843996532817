import axios from "axios";
import store from "@/store";
import { _rotasAPI } from "@/services/_caminhosApi";
import { tokenExpirado } from "@/utils/tokenExpirado";

export function listarCupom(form, pag = 1) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .post(
        `${_rotasAPI().listarCupom}?pagina=${pag}&quantidadePorPagina=${15}`,
        {
          status: form.status.id,
          chave: form.chave,
          numeroCupom: form.numero,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },        
      )
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("cupom/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function detalhesCupom(id) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().detalhesCupom}${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function listarStatus() {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().listarStatus, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("cupom/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function alterarStatus(parametrosDaRota) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .put(
        _rotasAPI().alterarStatus, parametrosDaRota,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("cupom/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function produtoParticipante(promocaoId) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(`${_rotasAPI().produtoParticipante}${promocaoId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("cupom/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}

export function moderarCupom(cupom, produtos) {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    const prods = [];
    produtos.forEach((value) => {
      prods.push({
        quantidade: parseInt(value.quantidade),
        valor: parseFloat(value.valor.replaceAll(",", ".").replaceAll("R$", "")),
        produtoId: value.idProduto,
      });
    });
    if(!cupom.cnpjEstabelecimento){
      store.commit('SET_MODAL', {
        ativado: true,
        mensagem: "Preencha o campo CNPJ do estabelecimento e tente novamente.",
        tipo: 'erro',
      });
    }else{
      if(cupom.cpfDaNota) {
        var cpfCliente = cupom.cpfDaNota.replace(/[^0-9]+/g,'')
      }
      const infoCupom = {
        id: cupom.id,
        usuarioPainelId: cupom.usuarioPainelId,
        produtos: prods,
        numeroCupom: cupom.numeroCupom,
        cpfCliente,
        cnpjEstabelecimento: cupom.cnpjEstabelecimento
          .replaceAll(".", "")
          .replaceAll("/", "")
          .replaceAll("-", ""),
        uf: cupom.uf,
        valor: parseFloat(cupom.valor),
        dataCompra: cupom.dataCompra,
        comentario: "Em Análise",
      };
      store.commit("SET_ADICIONAR_LOADING");
      axios
        .post(_rotasAPI().moderarCupom, infoCupom, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((erro) => {
          store.commit("cupom/SET_CARREGADO", true);
          tokenExpirado(erro, true);
        })
        .finally(() => store.commit("SET_REMOVER_LOADING"));
    }
  });
}

export function relatorioCupom() {
  return new Promise((resolve) => {
    const token = localStorage.getItem("token");
    store.commit("SET_ADICIONAR_LOADING");
    axios
      .get(_rotasAPI().relatorioCupom, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        store.commit("user/SET_CARREGADO", true);
        tokenExpirado(erro, true);
      })
      .finally(() => store.commit("SET_REMOVER_LOADING"));
  });
}
